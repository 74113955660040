import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Title, Button, Section, Box, Text} from "../../components/Core";
import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";
import {Link} from "gatsby";

const LeftCard = styled(Box)`
  position: absolute;
  top: 0;
  left: 0px;
`;

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;

const CTA = () => (
    <>
        {/* <!-- CTA section --> */}
        <Section className="position-relative" style={{background: "#efeffc"}}>
            <LeftCard
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
            >
            </LeftCard>
            <RightCard>
                <img src={svgCurve} alt="" className="img-fluid"/>
            </RightCard>
            <Container>
                <Row className="justify-content-center text-center">
                    <Col lg="6">
                        <Box mb={5} className="text-center">
                            <Title color="dark">Want to join?</Title>
                            <Text color="dark" opacity={0.7}>
                                Looking for a new career or the next step in your career?
                                Check out our job openings, apply for your suitable role and become part of a team committed to delivering
                                the fastest broadband possible to everyone in the UK.
                            </Text>
                        </Box>
                        <Box>
                            <Link to="/careers/">
                                <Button>YouFibre Careers</Button>
                            </Link>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Section>
    </>
);

export default CTA;