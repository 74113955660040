import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Button, MainTitle } from "../../components/Core";
import { Link } from 'gatsby';
import Image from "gatsby-image";
import netomniaLogo from './../../assets/image/svg/netomnia-logo.svg'
import cityFibreLogo from './../../assets/image/svg/cityfibre-logo.svg'
import ukMap from './../../assets/image/svg/uk-map.svg'
import CheckAvailability from "../../components/Rebrand/CheckAvailability/CheckAvailability"

const BigTitle = styled(Title)`{
  font-size: 3.5em;
}`

const Content = ({pageContent}) => (
    <>
        <Section>
            <Container>
                <Row className="justify-content-center pb-4">
                    <Col lg="6">
                        <MainTitle>{pageContent.section_subtitle_1}</MainTitle>
                    </Col>
                    <Col lg="6" className="pl-lg-5">
                        <Text>
                            {pageContent.section_text_1}
                        </Text>
                        <br></br>
                        <Button
                            size="sm"
                            css={`
                                font-size: 16px !important;
                                min-width: 141px !important;
                                height: 45px !important;
                            `}
                            onClick={() => {
                              navigate('/why-youfibre/');
                            }}
                          >
                            Why YouFibre?
                        </Button>
                    </Col>
                </Row>
                {/* <Row className="mt-5">
                    <Col lg="4" sm="5" className="mb-4 ">
                        <Image fluid={pageContent.section_image_1.childImageSharp.fluid} loading="eager" className="img-fluid" alt="YouFibre Ultrafast Broadband - How Does It Work"/>
                    </Col>
                    <Col lg="8" sm="7" className="mb-4">
                        <Image fluid={pageContent.section_image_2.childImageSharp.fluid} loading="eager" className="img-fluid" />
                    </Col>
                </Row> */}
            </Container>
        </Section>

        <Section className="pt-0">
            <Container>
                <Row className="justify-content-center pb-4">
                    <Col sm="12" className="text-center pb-4">
                        <BigTitle>
                            {pageContent.section_subtitle_2}
                        </BigTitle>
                    </Col>
                    <Col sm="12" className="pl-lg-5 text-center">
                        <Text>{pageContent.section_text_2}</Text>
                        <br></br>
                        <Button
                            size="sm"
                            css={`
                                font-size: 16px !important;
                                min-width: 141px !important;
                                height: 45px !important;
                            `}
                            onClick={() => {
                                navigate('/contact/');
                            }}
                            >
                            Contact us
                        </Button>
                        <br></br>
                        <br></br>
                        <Text>{pageContent.section_text_2_1}</Text>
                        <br></br>
                        <Button
                            size="sm"
                            css={`
                                font-size: 16px !important;
                                min-width: 141px !important;
                                height: 45px !important;
                            `}
                            onClick={() => {
                                navigate('/');
                            }}
                            >
                            View our broadband packages
                        </Button>
                    </Col>
                </Row>
                {/* <Row className="mt-5 text-center">
                    <Col sm="12" className="mb-4">
                        <Image fluid={pageContent.section_image_3.childImageSharp.fluid} loading="eager" className="img-fluid" />
                    </Col>
                </Row> */}
            </Container>
        </Section>

        <Section className="pt-0">
            <Container>
                <Row className="justify-content-center pb-4 align-items-center">
                    <Col md="6" className="text-center">
                        <BigTitle>
                            Our Network
                        </BigTitle>
                        <Text>
                            We work tirelessly with our infrastructure partners to bring full fibre to homes and businesses all over the UK. Having not only fast internet speed but a broadband service you can always rely on matters to you and matters to us.
                        </Text>
                        <br></br>
                        <Text>
                            The pink dots on this map show where our fibre to the premises service is live today, whilst the blue show areas we are coming to soon. Exciting!
                        </Text>
                        <br></br>
                        <Title variant="card" mb={2}>
                            Check if you can get YouFibre
                        </Title>
                        <CheckAvailability center focusInput={false} minimized={true} salesManager={null}/>
                        <Title className="mt-5" variant="card" fontSize="24px" letterSpacing={-0.75} my={2}>Our Infrastructure Partners</Title>
                        <br></br>
                        <a href="https://www.netomnia.com/" className="d-block" target="_blank">
                            <img width={"150px"} src={netomniaLogo}/>
                        </a>
                        <br></br>
                        <a href="https://cityfibre.com/" className="d-block" target="_blank">
                            <img width={"150px"} src={cityFibreLogo}/>
                        </a>
                    </Col>
                    <Col md="5" className="text-center">
                        <div className="netomnia-map-wrapper">
                            <img src={ukMap}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    </>
);

export default Content;
