import React from "react";
import Content from "../sections/about/Content"
import Team from "../sections/about/Team"
import CTA from "../sections/about/CTA"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import Seo from "../components/Seo/Seo";
import { Title } from "../components/Core";

const About = () => {

    const data = useStaticQuery(graphql`
        query AboutSectionQuery {
            strapiAboutSection {
                section_title
                section_subtitle_1
                section_text_1
                section_subtitle_2
                section_text_2
                section_text_2_1
                section_text_2_1
                section_text_2_1  
                section_subtitle_3
                section_text_3
                section_subtitle_4
                section_text_4
            }
        }
    `);

    const pageData = getProperty(data, 'strapiAboutSection');

    return (
        <div>
            <Seo page="about" />
            <div className="pt-5"></div>
            <Content
                pageContent={pageData}
            />
            <Team />
            <CTA />
        </div>
    )
}
export default About
