import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Title, Section, Box, Text} from "../../components/Core";
import jeremyImage from "../../assets/image/png/youfibre-jeremy.png"

const CardImage = styled.div`
  max-width: 160px;
  min-width: 160px;
  min-height: 160px;
  max-height: 160px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 29px;
`;

const TeamCard = ({userImg, title, children, ...rest}) => (
    <Box
        className="text-center"
        pt="15px"
        px="30px"
        borderRadius={10}
        mb={4}
        {...rest}
    >
        <CardImage>
            <img src={userImg} className="img-fluid" alt="Jeremy Chelot - YouFibre"/>
        </CardImage>
        <div className="text-center">
            <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
                {title}
            </Title>
            <Text fontSize={2} lineHeight={1.75}>
                {children}
            </Text>
        </div>
    </Box>
);

const Team = () => (
    <>
        {/* <!-- Team section --> */}
        <Section className="position-relative pt-5">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8" className="text-center pb-3">
                        <div className="">
                            <Title>A Note from the Founder</Title>
                            <Text>
                            My interest for computers and the Internet developed at the age of 7 when I became fascinated by how they were changing the way we learn, play, and live. I founded YouFibre because I want everybody to benefit from a truly fast and affordable Internet service to unleash their full potential. We're building a network that's capable of delivering up to (a huge) 10,000 Mbps. Feel free to reach out, I would love to hear from you, I would love to help you.
                            </Text>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <TeamCard userImg={jeremyImage} title="Jeremy Chelot">
                            Founder and CEO
                        </TeamCard>
                    </Col>
                </Row>
            </Container>
        </Section>
    </>
);

export default Team;
